// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import 'jquery'

$(function() {
  // Set current date based on user's OS datetime
  const today = new Date();
  const formattedDate = `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear() % 100}`;
  $('#today-date').text(formattedDate);

  // set time left on day
  const hoursPassed = today.getHours();
  const hoursLeft = 24 - hoursPassed;
  var hoursLeftText;

  if (hoursLeft === 1) {
    hoursLeftText = `a few minutes left`;
  } else {
    hoursLeftText = `${hoursLeft} hours left`
  }

  $('#time-left').text(hoursLeftText);
});
